import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid } from '@material-ui/core';

import { getColor } from '../Config';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        color: theme.palette.common.black,
        textAlign: 'center'
    },
});

function SectionCard(props) {

    const { classes } = props;

    return (
        <Grid item xs={12} md={9}>
            <Card className={classes.card}>
                <CardContent>
                    {props.children}
                </CardContent>
            </Card>
        </Grid>
    );
}

SectionCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SectionCard);