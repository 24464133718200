import React from 'react';
import { Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Main from '../pages/Main';
import AboutPage from '../pages/AboutPage';

import ServicesPage from '../pages/ServicesPage';
import CateringPage from '../pages/services/CateringPage';
import DietitianPage from '../pages/services/DietitianPage';
import MassagePage from '../pages/services/MassagePage';
import MealPrepPage from '../pages/services/MealPrepPage';

import AllBlogsPage from '../pages/AllBlogsPage';
import IndividualBlogPage from '../pages/IndividualBlogPage';

import LoginPage from '../pages/dashboard/LoginPage';
import LogoutPage from '../pages/dashboard/LogoutPage';
import DashboardPage from '../pages/dashboard/DashboardPage';

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: 0,
        minWidth: 0
    },
});

const Routes = props => {

    const { classes } = props;

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                <Route exact path="/" component={Main} />
                <Route path="/main" component={Main} />
                <Route path="/about" component={AboutPage} />

                <Route path="/services" component={ServicesPage} />
                <Route path="/service/catering" component={CateringPage} />
                <Route path="/service/dietitian" component={DietitianPage} />
                <Route path="/service/massage" component={MassagePage} />
                <Route path="/service/mealprep" component={MealPrepPage} />

                <Route path="/blogs" component={AllBlogsPage} />
                <Route path="/blog/:blogId" component={IndividualBlogPage} />

                <Route path="/login" component={LoginPage} />
                <Route path="/logout" component={LogoutPage} />
                <Route path="/dashboard" component={DashboardPage} />
            </main>
        </div>
    );
};

Routes.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Routes);