import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import BlogFeatured from '../components/BlogFeatured';

const styles = theme => ({
    titlePaper: {
        backgroundImage: 'url(/images/kiwi.jpg)',
        backgroundSize: 'cover',
        marginBottom: theme.spacing.unit * 4,
        paddingTop: `${theme.spacing.unit * 10}px`,
        paddingBottom: `${theme.spacing.unit * 10}px`,
        borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`
    },
    titleText: {
        textShadow: '2px 2px white',
        textAlign: 'center'
    },
});

function MainPage(props) {
    
    const { classes } = props;
    
    return (
        <React.Fragment>
            <Paper className={classes.titlePaper}>
                <div className={classes.servicesContainer}>
                    <Typography component="h1" variant="h3" color="inherit" gutterBottom className={classes.titleText}>
                        Wabi Sabi Body
                    </Typography>
                </div>
            </Paper>
            <BlogFeatured />
            <Services />
            <Testimonials />
        </React.Fragment>
    );
}

MainPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainPage);