import React, { useState } from "react";
import { Paper } from "@material-ui/core/";

import DateFnsUtils from "@date-io/date-fns";
import { BasePicker, MuiPickersUtilsProvider, Calendar } from "material-ui-pickers";

function StaticDatePicker(props) {
    const [selectedDate, handleDateChange] = useState(new Date());

    const interceptHandleDateChange = newDate => {
        handleDateChange(newDate);
        props.setDate(newDate);
    }

    const shouldDisableDate = date => !props.enabledDays.includes(date.toLocaleDateString("en-US"));

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BasePicker value={selectedDate} onChange={interceptHandleDateChange}>
                {({
                date,
                handleChange,
                }) => (
                    <div className="picker">
                        <Paper style={{ overflow: "hidden" }}>
                            <Calendar date={date} onChange={interceptHandleDateChange} shouldDisableDate={shouldDisableDate}/>
                        </Paper>
                    </div>
                )}
            </BasePicker>
        </MuiPickersUtilsProvider>
    );
}

export default StaticDatePicker;