import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Section from './Section';
import FoodCards from './FoodCards';
import { getFoods } from '../ajax/FoodService';

const foodDefinitions = getFoods();

const styles = theme => ({
    center: {
        textAlign: 'center'
    }
});

class FoodSection extends Component {

    render() {
        let visible = this.props.section.foods
            .map(foodId => foodDefinitions[foodId])
            .filter(food => (this.props.selectedTags.length === 0 || this.props.selectedTags.some(r => food.tags.includes(r))))
            .length > 0;

        if (!visible) {
            return '';
        }

        return (
            <Section key={this.props.section.title} title={this.props.section.title} variant="h4">
                <FoodCards foods={this.props.section.foods} checkedState={this.props.checkedState} checkedChanged={this.props.checkedChanged} selectedTags={this.props.selectedTags} />
            </Section>
        );
    }
}

export default withStyles(styles)(FoodSection);