import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';

import { getColor } from '../Config';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    avatarContainer: {
        padding: '10px',
    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: 'auto'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            height: 'auto'
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: 'auto'
        }
    },
});

function Testimonial(props) {
    
    const { classes } = props;
    
    return (
        <Grid item key={props.testimonial.name} md={9} xs={12}>
            <Card className={classes.card}>
                <div className={classes.cardDetails}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={3} className={classes.avatarContainer}>
                                <Avatar alt={props.testimonial.name} src={props.testimonial.image} className={classes.avatar} />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography component="h2" variant="h5">{props.testimonial.name}</Typography>
                                {props.testimonial.text.map((line, index) => (
                                    <Typography key={line+index} variant="subtitle1" paragraph>{line}</Typography>
                                ))}
                            </Grid>
                        </Grid>
                    </CardContent>
                </div>
            </Card>
        </Grid>
    );
}

Testimonial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Testimonial);