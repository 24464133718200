import React from 'react';
import PropTypes from 'prop-types';

class LogoutPage extends React.Component {

    static contextTypes = {router: PropTypes.object};

    componentDidMount() {
        sessionStorage.removeItem('jwt');
        this.context.router.history.push(`/login`);
    }

    render() {
        return '';
    }
}

export default LogoutPage;