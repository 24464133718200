import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import FoodCard from './FoodCard';

import { getFoods } from '../ajax/FoodService';

const foodDefinitions = getFoods();

class FoodCards extends Component {

    render() {
        return (
            <Grid container item spacing={24} alignItems="stretch" direction="row" justify="center">
                {this.props.foods
                    .map(foodId => foodDefinitions[foodId])
                    .map(food => (
                        <FoodCard key={food.id} food={food} checkedState={this.props.checkedState[food.id]} checkedChanged={this.props.checkedChanged} selectedTags={this.props.selectedTags} />
                    )
                )}
            </Grid>
        );
    }
}

export default FoodCards;