import React from 'react';

import Services from '../components/Services';

function ServicesPage() {
    return (
        <React.Fragment>
            <Services />
        </React.Fragment>
    );
}

export default ServicesPage;