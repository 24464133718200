import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import RequestListItem from './RequestItem';
import QuoteService from '../../../ajax/QuoteService';

const styles = theme => ({
    center: {
        textAlign: 'center'
    },
});

class RequestList extends Component {
    
    state = {
        status: 'Loading...',
        requests: []
    };

    constructor(props) {
        super(props);
        this.quoteService = new QuoteService();
        this.fetchRequests = this.fetchRequests.bind(this);
    }

    fetchRequests() {
        const that = this;

        this.quoteService.getRequests(this.props.type)
            .then(response => response.json())
            .then(result => {
                if (result.length === 0) {
                    that.setState({requests: result, status: 'There are no requests.'})
                } else {
                    that.setState({requests: result, status: ''})
                }
            })
            .catch(error => {
                that.setState({status: 'Error occurred while retrieving quotes/appointments'});
                console.error(error);
            });
    }

    componentDidMount() {
        this.fetchRequests();
    }

    render() {
        return (
            <Grid item xs={12} sm={6}>
                <Typography component="h3" variant="h5" color="inherit" gutterBottom className={this.props.classes.center}>
                    New quote/appointment requests
                </Typography>
                <Typography variant="subtitle1" color="inherit" gutterBottom className={this.props.classes.center}>
                    {this.state.status}
                </Typography>
                <Grid container spacing={24}>
                    {this.state.requests.map(request => (
                        <RequestListItem key={request.id} request={request} refetch={this.fetchRequests}/>
                    ))}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(RequestList);