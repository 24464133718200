import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, Grid } from '@material-ui/core';

import BlogService from '../ajax/BlogService';
import Section from './Section';
import { getColor } from '../Config';
import Markdown from './Markdown';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        color: theme.palette.common.black,
        textAlign: 'center'
    },
});

class BlogFeatured extends React.Component {

    state = {status: '## Loading...', blurb: '', id: 0}

    constructor(props) {
        super(props);
        this.blogService = new BlogService();
    }

    async componentDidMount() {
        const blog = await this.blogService.getMostRecentBlog();
        this.setState({
            status: '',
            blurb: blog.blurb,
            id: blog.id
        });
    }
    
    render() {
        let button = '';

        if (this.state.id > 0) {
            button = <Button color="inherit" component={Link} to={`/blog/${this.state.id}`}>Continue reading...</Button>
        }

        return (
            <Section title="Latest Blog">
                <Grid item xs={12} md={9}>
                    <Card className={this.props.classes.card}>
                        <CardContent>
                            <Markdown className={this.props.classes.markdown}>
                                {this.state.status + this.state.blurb}
                            </Markdown>
                            {button}
                        </CardContent>
                    </Card>
                </Grid>
            </Section>
        );
    }
}

BlogFeatured.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BlogFeatured);