import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Card, CardActionArea, CardContent, CardMedia, Chip, Grid, Typography } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, Check } from '@material-ui/icons';

import { getColor } from '../Config';

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit / 2,
    },
    card: {
        backgroundColor: getColor('bg-secondary'),
        border: '5px solid white',
        margin: '0'
    },
    checkIcon: {
        fontSize: '30pt',
        position: 'absolute',
        right: '0',
        margin: '-5px',
        backgroundColor: getColor('bg-secondary'),
        borderRadius: '0px 0px 0px 3px',
        borderWidth: '0px 0px 1px 1px'
    },
    center: {
        textAlign: 'center'
    }
});

class FoodCard extends Component {

    constructor(props) {
        super(props);

        this.state = {checked: false};
    }

    render() {
        if (!(this.props.selectedTags.length === 0 || this.props.selectedTags.some(r => this.props.food.tags.includes(r)))) {
            return '';
        }

        let checkbox;

        if (this.state.checked) {
            checkbox = <CheckBox color="primary" className={this.props.classes.checkIcon} />;
        } else {
            checkbox = <CheckBoxOutlineBlank color="primary" className={this.props.classes.checkIcon} />
        }

        return (
            <Grid item key={this.props.food.name} xs={12} md={3}>
                <Card className={this.props.classes.card}>
                    <CardActionArea onClick={() => {
                        this.setState({checked: !this.state.checked});
                        this.props.checkedChanged(this.props.food.id);
                    }}>
                        {checkbox}
                        <CardMedia
                            component="img"
                            alt={this.props.food.name}
                            title={this.props.food.name}
                            className={this.props.classes.media}
                            image={this.props.food.image}
                            />
                        <CardContent className={this.props.classes.center}>
                            <Typography component="h2" variant="h5">
                                {this.props.food.name}
                            </Typography>
                            <Typography variant="subtitle1" paragraph>{this.props.food.description}</Typography>
                            {this.props.food.tags.map(tag => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    color="primary"
                                    className={this.props.classes.chip}
                                    avatar={
                                        <Avatar>
                                            <Check />
                                        </Avatar>
                                    } />
                            ))}
                            {this.props.food.badTags.map(tag => (
                                <Chip
                                    key={tag}
                                    label={tag}
                                    color="secondary"
                                    className={this.props.classes.chip}
                                    avatar={<Avatar>X</Avatar>} />
                            ))}
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(FoodCard);