//import lightGreen from '@material-ui/core/colors/lightGreen';
//import amber from '@material-ui/core/colors/lightGreen';
//a400 and 500

const API_ADDRESS = 'https://raeann.prop.st/';
//const API_ADDRESS = 'http://192.168.0.26/';

const STRINGS = {
    'phone': '555-555-5555',
    'email': 'info@wabisabibody.com',
    'About.description': `Wabi Sabi is a term related to the beauty in a life lived, and the worn parts. We are a Knoxville, TN based collective [pending] that I don't know, some words here maybe. Lorem ipsum dolor amet direct trade tumblr williamsburg chambray. Copper mug sustainable slow-carb knausgaard, typewriter fam selfies authentic XOXO 90's food truck scenester mixtape lumbersexual meditation. Poutine hashtag forage hella sriracha pok pok stumptown everyday carry try-hard single-origin coffee pitchfork woke hell of bicycle rights. Chicharrones vice iPhone sriracha hexagon normcore cloud bread. Pickled lumbersexual taxidermy williamsburg tousled salvia. Shaman waistcoat synth drinking vinegar, iceland shabby chic freegan gentrify.`,
    'About.raeann': [`I'm comfortable working with multiple diets to make everyone in the family happy (raw vegan, vegan, vegetarian, gluten free, meat, and everything in between) to ensure everyone gets the highest caliber meals without being offered a consultation slab of something boring.`,
                     `I can do simple and I can do gourmet.`,
                     `I'm scratch and prefer to work with local meats and produce, although I can do cheaper, more basic stuff.`],
    'About.notacat': ['Our dietician.',
                      'Loves fish.',
                      'Not a cat; please stop asking.']
};

const COLORS = {
    'bg-primary': '#ffd453',
    'bg-secondary': "#ffffff"
};

function getApiAddress() {
    return API_ADDRESS;
}

function getString(stringName) {
    return STRINGS[stringName];
}

function getColor(colorName) {
    return COLORS[colorName];
}

export {
    getString,
    getColor,
    getApiAddress
};