import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Fab, Paper, Typography } from '@material-ui/core';

import RequestQuoteDialog from '../../components/RequestQuoteDialog';
import FoodSections from '../../components/FoodSections';

import { getFoods } from '../../ajax/FoodService';
import { getColor } from '../../Config';

const styles = theme => ({
    main: {
        backgroundColor: getColor('bg-primary'),
        color: theme.palette.common.black,
        marginBottom: theme.spacing.unit * 4,
        textAlign: 'center'
    },
    mainContent: {
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing.unit * 2}px`,
        },
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing.unit * 4}px`,
            paddingTop: `${theme.spacing.unit}px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 4}px`,
            paddingTop: `${theme.spacing.unit * 2}px`,
        }
    },
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: 20,
        right: 20,
        position: 'fixed',        
        zIndex: 5000
    },
});

const foods = getFoods();

function CateringPage(props) {

    const [checked, setChecked] = useState({});
    const { classes } = props;

    const handleCheckedChanged = (foodId) => {
        checked[foodId] = !checked[foodId];
        setChecked(checked);
    };

    const showQuoteDialog = () => {
        let selectedFoods = Object.entries(checked)
            .filter(pair => pair[1])
            .map(pair => pair[0])
            .map(foodId => 'Catering Special Event - ' + foods[foodId].name)

        RequestQuoteDialog.openDialog({
            type: 'Quote',
            typeDisplay: 'a quote',
            service: 'Catered Special Event',
            detailedServices: selectedFoods,
        });
    };

    return (
        <React.Fragment>
            
            <Fab color="primary" variant="extended" aria-label="Request a Quote" className={classes.fab} onClick={showQuoteDialog}>
                Request a Quote
            </Fab>
            <Paper className={classes.main}>
                <Grid container spacing={24}>
                    <Grid item>
                        <div className={classes.mainContent}>
                            <Typography component="h3" variant="h3" color="inherit" gutterBottom className={classes.center}>
                                Catered Special Events
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph className={classes.center}>
                                For our catered special events service, you choose the meal you want to eat for your special event, and we come to you and prepare it for your date or party.
                            </Typography>
                            <Typography variant="h5" color="inherit" paragraph className={classes.center}>
                                Below is a sample of our offerings. To get a quote, select one or more food items and click the "Request a Quote" button.
                            </Typography>
                            <Button color="primary" onClick={showQuoteDialog}>
                                Request a Quote
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <FoodSections checkedState={checked} checkedChanged={handleCheckedChanged} />
        </React.Fragment>
    );
}

CateringPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CateringPage);