import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import Alert from './components/Alert';
import RequestQuoteDialog from './components/RequestQuoteDialog';
import Routes from './routes/index';
import './App.css';

const styles = theme => ({
    layout: {
        width: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            marginRight: theme.spacing.unit * 3,
        },
        [theme.breakpoints.up('lg')]: {
            width: '1400px',
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
});

const history = createBrowserHistory();

class App extends Component {

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <Alert />
                <RequestQuoteDialog />
                <Router history={history}>
                    <div className={this.props.classes.layout}>
                        <PageHeader />
                        <Routes />
                        <PageFooter />
                    </div>
                </Router>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(App);