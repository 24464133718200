import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Button, Grid, Hidden, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { getColor } from '../Config';

const styles = theme => ({
    toolbar: {
        boxShadow: 'none',
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
    },
    toolbarMain: {
        borderBottom: `1px solid ${theme.palette.common.black}`,
        backgroundColor: getColor('bg-secondary'),
        color: theme.palette.common.black,
    },
});

function PageHeader(props) {
    
    const { classes } = props;
    const [anchorElement, setAnchorElement] = useState(null);

    const handleClick = event => {
        setAnchorElement(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorElement(null);
    }
    
    return (
        <AppBar position="static" className={classes.toolbar}>
            <Toolbar className={classes.toolbarMain}>
                <Hidden smDown>
                    <Grid container spacing={16} justify="space-between">
                        <Grid item xs={12} md={6}>
                            <Button color="inherit" size="large" component={Link} to="/">Wabi Sabi Body</Button>
                            <Button color="inherit" component={Link} to="/about">About Us</Button>
                            <Button color="inherit" component={Link} to="/blogs">Blog</Button>
                            <Button color="inherit" component={Link} to="/services">Services</Button>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <IconButton className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        aria-haspopup="true"
                        aria-owns={anchorElement ? 'simple-menu' : undefined}
                        onClick={handleClick}
                        >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="header-menu"
                        anchorEl={anchorElement}
                        open={Boolean(anchorElement)}
                        onClose={handleClose}>
                        <MenuItem component={Link} to="/" onClick={handleClose}>Home</MenuItem>
                        <MenuItem component={Link} to="/about" onClick={handleClose}>About Us</MenuItem>
                        <MenuItem component={Link} to="/blogs" onClick={handleClose}>Blog</MenuItem>
                        <MenuItem component={Link} to="/services" onClick={handleClose}>Services</MenuItem>
                    </Menu>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        Wabi Sabi Body
                    </Typography>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

PageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageHeader);