import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Chip, Divider, Fab, Grid, Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Section from '../../components/Section';
import SectionCard from '../../components/SectionCard';
import RequestQuoteDialog from '../../components/RequestQuoteDialog';
import FoodSections from '../../components/FoodSections';

import { getFoods } from '../../ajax/FoodService';

const styles = theme => ({
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: 20,
        right: 20,
        position: 'fixed',        
        zIndex: 5000
    },
    chip: {
        margin: theme.spacing.unit,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

let flattenES6 = arr => [].concat.apply([], arr.map(element => Array.isArray(element) ? flattenES6(element) : element))
const foods = getFoods();
const allTags = [...new Set(flattenES6(Object.values(foods).map(food => food.tags)))];

function MealPrep(props) {

    const [checked, setChecked] = useState({});
    const [selectedTags, setSelectedTags] = useState([]);
    const { classes } = props;

    const handleCheckedChanged = (foodId) => {
        checked[foodId] = !checked[foodId];
        setChecked(checked);
    };

    const showQuoteDialog = () => {
        let selectedFoods = Object.entries(checked)
            .filter(pair => pair[1])
            .map(pair => pair[0])
            .map(foodId => 'Meal Prep - ' + foods[foodId].name)

        RequestQuoteDialog.openDialog({
            type: 'Quote',
            typeDisplay: 'a quote',
            service: 'Meal Prep',
            detailedServices: selectedFoods,
        });
    };

    const createChipClickHandler = tag => () => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(tagInArray => tagInArray !== tag));
        } else {
            setSelectedTags([...selectedTags, tag])
        }
    }

    return (
        <React.Fragment>
            <Fab color="primary" variant="extended" aria-label="Request a Quote" className={classes.fab} onClick={showQuoteDialog}>
                Request a Quote
            </Fab>
            <Section title="Food">
                <SectionCard>
                    <Typography variant="h5" color="inherit" paragraph>
                        For our weekly meal prep service, you choose the meals you want to eat throughout the week, and we come to you and prepare them all at once, then you simple heat the food when you are ready to eat it.
                    </Typography>
                    <Typography variant="h5" color="inherit" paragraph>
                        Below is a sample of our offerings. To get a quote, select one or more food items and click the "Request a Quote" button.
                    </Typography>
                    <Button color="primary" onClick={showQuoteDialog}>
                        Request a Quote
                    </Button>
                    <Divider />
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className={classes.heading}>Filter</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography paragraph>Click one or more of the following tags to limit the food options:</Typography>
                                    {allTags.map(tag => (
                                        <Chip
                                            key={tag}
                                            className={classes.chip}
                                            label={tag}
                                            onClick={createChipClickHandler(tag)}
                                            color={selectedTags.includes(tag) ? "primary" : "default"}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </SectionCard>
            </Section>
            <FoodSections checkedState={checked} checkedChanged={handleCheckedChanged} selectedTags={selectedTags} />
        </React.Fragment>
    );
}

MealPrep.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MealPrep);