import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid } from '@material-ui/core';

import BlogService from '../ajax/BlogService';
import Section from '../components/Section';
import { getColor } from '../Config';
import Markdown from '../components/Markdown';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        color: theme.palette.common.black,
        textAlign: 'center'
    },
});

class IndividualBlogPage extends React.Component {

    state = {status: '## Loading...', text: ''}

    constructor(props) {
        super(props);
        this.blogService = new BlogService();
    }

    async componentDidMount() {
        const { blogId } = this.props.match.params;
        const blog = await this.blogService.getBlog(blogId);

        this.setState({
            status: '',
            text: blog.full,
        });
    }
    
    render() {
        return (
            <Section title="WabiSabiBodyBlog">
                <Grid item xs={12} md={9}>
                    <Card className={this.props.classes.card}>
                        <CardContent>
                            <Markdown className={this.props.classes.markdown}>
                                {this.state.status + this.state.text}
                            </Markdown>
                        </CardContent>
                    </Card>
                </Grid>
            </Section>
        );
    }
}

export default withStyles(styles)(IndividualBlogPage);