import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid } from '@material-ui/core';

import BlogService from '../ajax/BlogService';
import Section from '../components/Section';
import { getColor } from '../Config';
import Markdown from '../components/Markdown';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        color: theme.palette.common.black,
        textAlign: 'center',
        marginBottom: theme.spacing.unit * 4
    },
});

function BlogCard(props) {
    const { classes } = props;
    let button = '';

    if (props.id > 0) {
        button = <Button color="inherit" component={Link} to={`/blog/${props.id}`}>Continue reading...</Button>;
    }

    return (
        <Grid item xs={12} md={9}>
            <Card className={classes.card}>
                <CardContent>
                    <Markdown className={classes.markdown}>
                        {props.text}
                    </Markdown>
                    {button}
                </CardContent>
            </Card>
        </Grid>
    );
}

class AllBlogsPage extends React.Component {

    state = {loaded: false, text: ''}

    constructor(props) {
        super(props);
        this.blogService = new BlogService();
    }

    async componentDidMount() {
        const blogs = await this.blogService.getPage(1);

        this.setState({
            loaded: true,
            blogs: blogs,
        });
    }
    
    render() {
        let content;

        if (!this.state.loaded) {
            content = <BlogCard {...this.props} text="## Loading..." />; 
        } else {
            content = this.state.blogs.map(blog => (
                <BlogCard {...this.props} text={blog.blurb} id={blog.id} key={blog.id} />
            ));
        }

        return (
            <Section title="WabiSabiBodyBlog">
                {content}
            </Section>
        );
    }
}

export default withStyles(styles)(AllBlogsPage);