import React from 'react';
import { Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormLabel, List, ListItem, ListItemText, Slide,
    TextField } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers';

import QuoteService from '../ajax/QuoteService';
import Alert from './Alert';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class RequestQuoteDialog extends React.Component {

    static openDialog(data = {type: 'Type', typeDisplay: 'Type Display', service: 'Service Name', detailedServices: []}) {
        RequestQuoteDialog.__singletonRef.__openDialog(data);
    }

    state = {
        open: false,
        selectedServices: [],
        name: '',
        email: '',
        phone: '',
        address: '',
        desiredDate: new Date(),
        additionalInformation: ''
    };

    constructor(props) {
        super(props);
        RequestQuoteDialog.__singletonRef = this;
        this.quoteService = new QuoteService();
    }

    __handleDateChange = date => {
        this.setState({ desiredDate: date });
    }

    __handleInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState({
            [name]: value
        });
    }

    __openDialog = (data = {type: 'Type', typeDisplay: 'Type Display', service: 'Service Name', detailedServices: []}) => {
        this.setState({
            open: true,
            type: data.type,
            typeDisplay: data.typeDisplay,
            service: data.service,
            detailedServices: data.detailedServices,
        });
    };

    __handleClose = () => {
        this.setState({ open: false });
    };

    __handleSubmit = () => {
        const data = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            serviceName: this.state.service,
            additionalInformation: this.state.additionalInformation,
            serviceDetails: this.state.detailedServices,
            desiredTime: this.state.desiredDate
        };

        this.quoteService.createRequest(data)
            .then(res => {
                Alert.displayMessage('Request submitted. Someone will be in touch.');
                this.__handleClose();
            }).catch(error => {
                Alert.displayError('An error occurred.');
                console.error('Error: ', error)
            });
    }

    render() {
        const width = window.innerWidth;
        let services;

        if (this.state.detailedServices == null || this.state.detailedServices.length === 0) {
            services = <ListItem><ListItemText primary="None selected" /></ListItem>;
        } else {
            services = this.state.detailedServices.map(service => (
                <ListItem key={service}>
                    <ListItemText primary={service} />
                </ListItem>
            ));
        }
        
        return (
            <Dialog
                fullScreen={width < 960}
                open={this.state.open}
                onClose={this.__handleClose}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Request {this.state.typeDisplay}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To request {this.state.typeDisplay}, please fill out and submit the following form.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        type="text"
                        fullWidth
                        value={this.state.name}
                        onChange={this.__handleInputChange}
                        />
                    <TextField
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={this.state.email}
                        onChange={this.__handleInputChange}
                        />
                    <TextField
                        margin="dense"
                        id="phone"
                        name="phone"
                        label="Phone"
                        type="phone"
                        fullWidth
                        value={this.state.phone}
                        onChange={this.__handleInputChange}
                        />
                    <TextField
                        margin="dense"
                        id="address"
                        name="address"
                        label="Event Address"
                        type="text"
                        fullWidth
                        value={this.state.address}
                        onChange={this.__handleInputChange}
                        />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            fullWidth
                            value={this.state.desiredDate}
                            disablePast
                            onChange={this.__handleDateChange}
                            label="Desired date and time of service"
                            showTodayButton />
                    </MuiPickersUtilsProvider>
                    <FormLabel margin="dense">Service</FormLabel>
                    <List dense={true}>
                        <ListItem>
                            <ListItemText primary={this.state.service} />
                        </ListItem>
                    </List>
                    <FormLabel margin="dense">Detailed Services</FormLabel>
                    <List dense={true}>{services}</List>
                    <TextField
                        margin="dense"
                        id="message"
                        name="additionalInformation"
                        label="Additional information for request"
                        type="text"
                        multiline={true}
                        rows={4}
                        rowsMax={4}
                        fullWidth
                        value={this.state.additionalInformation}
                        onChange={this.__handleInputChange}
                        />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.__handleClose} color="inherit">
                        Cancel
                    </Button>
                    <Button onClick={this.__handleSubmit} color="primary">
                        Request
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default RequestQuoteDialog;