import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Section from '../../components/Section';
import SectionCard from '../../components/SectionCard';
import RequestQuoteDialog from '../../components/RequestQuoteDialog';
import MassageAppointmentStepper from './MassageAppointmentStepper';

const styles = theme => ({
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: 20,
        right: 20,
        position: 'fixed',        
        zIndex: 5000
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

function MassagePage(props) {

    const { classes } = props;

    const showQuoteDialog = (location) => {
        RequestQuoteDialog.openDialog({
            type: 'Appointment',
            typeDisplay: 'an appointment',
            service: 'Massage',
            detailedServices: [location],
        });
    };

    return (
        <React.Fragment>
            <Section title="Massage">
                <SectionCard>
                    <Typography color="inherit" paragraph className={classes.center}>
                        Our massage therapist is trained in some kind of massage therapy. We offer in-home massages and massages at our location. For massages at our location, you may spontaneously receive a foot rub from our feline massage therapist, BB.
                    </Typography>
                    <Typography color="inherit" paragraph className={classes.center}>
                        Some more words here if needed. Lorem ipsum dolor amet everyday carry four loko iPhone cred. Hashtag blue bottle ramps drinking vinegar cred fam. Microdosing neutra jean shorts fingerstache palo santo actually literally narwhal. Photo booth cliche man bun affogato. VHS actually tousled irony, cliche hammock unicorn.
                    </Typography>
                    <Typography color="inherit" paragraph className={classes.center}>
                        Click on the appropriate section below to request an appointment.
                    </Typography>
                </SectionCard>
                <Grid item xs={12} md={9}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Schedule a massage at our location</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <MassageAppointmentStepper />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>Request an in-home massage</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                            Due to the variability caused by travel times and setup/tear down, in-home appointments are not guaranteed. The massage therapist will need to review your request and get back to you.
                        </Typography>
                        <Button color="primary" onClick={() => showQuoteDialog('Massage appointment - in home')}>
                            Request an in-home massage
                        </Button>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                </Grid>
            </Section>
        </React.Fragment>
    );
}

MassagePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MassagePage);