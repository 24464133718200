import React from 'react';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { green, amber } from '@material-ui/core/colors';
import { IconButton, Snackbar, SnackbarContent, withStyles } from '@material-ui/core';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing.unit,
    },
});

class Alert extends React.Component {

    static displayMessage(message) {
        Alert.__singletonRef.__displayMessage(message);
    }

    static displayError(message) {
        Alert.__singletonRef.__displayError(message);
    }

    state = {
        open: false,
        message: '',
        variant: 'success'
    };

    constructor(props) {
        super(props);
        Alert.__singletonRef = this;
    }

    __displayMessage = (message) => this.setState({open: true, message: message, variant: 'success'});

    __displayError = (message) => this.setState({open: true, message: message, variant: 'error'});
    
    __handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            this.setState({open: false});
        }
    }

    render() {
        const Icon = variantIcon[this.state.variant];

        return (
            <React.Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.__handleClose}>
                    <SnackbarContent
                        className={classNames(this.props.classes[this.state.variant], this.props.classes.margin)}
                        aria-describedby="alert-snackbar"
                        message={
                            <span id="alert-snackbar" className={this.props.classes.message}>
                                <Icon className={classNames(this.props.classes.icon, this.props.classes.iconVariant)} />
                                {this.state.message}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={this.props.classes.close}
                                onClick={this.__handleClose}
                                >
                                <CloseIcon className={this.props.classes.icon} />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Alert);