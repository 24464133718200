import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography } from '@material-ui/core';

const styles = theme => ({
    sectionPaper: {
        //backgroundColor: getColor('bg-primary'),
        [theme.breakpoints.down('sm')]: {
            backgroundImage: 'url(/images/verticalbackground.jpg)',
            backgroundSize: '344px'
        },
        [theme.breakpoints.up('md')]: {
            backgroundImage: 'url(/images/verticalbackground.jpg)',
            backgroundSize: '344px'
        },
        color: theme.palette.common.white,
        marginBottom: theme.spacing.unit * 4,
    },
    sectionContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing.unit * 2}px`,
        },
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing.unit * 4}px`,
            paddingTop: `${theme.spacing.unit}px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 4}px`,
            paddingTop: `${theme.spacing.unit * 2}px`,
        }
    },
    titleText: {
        textShadow: '2px 2px black',
        textAlign: 'center'
    },
});

function Section(props) {

    const { classes } = props;

    return (
        <Paper className={classes.sectionPaper}>
            <Grid container spacing={8} className={classes.sectionContainer} justify="center">
                <Grid item xs={12}>
                    <Typography component="h2" variant={props.variant} color="inherit" gutterBottom className={classes.titleText}>
                        {props.title}
                    </Typography>
                </Grid>
                {props.children}
            </Grid>
        </Paper>
    );
}

Section.defaultProps = {
    variant: 'h3'
};

Section.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Section);