import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Hidden, Typography } from '@material-ui/core';

import { getColor } from '../Config';
import Section from './Section';

const styles = theme => ({
    card: {
        display: 'flex',
        backgroundColor: getColor('bg-secondary'),
        border: '5px solid white'
    },
    cardDetails: {
        flex: 1,
    },
    bigMedia: {
        width: 160
    },
    media: {
        objectFit: 'cover',
    },
    center: {
        textAlign: 'center'
    }
});

const services = [
    {
        title: 'Food',
        description: 'We come to you and make things that you can either eat that night or snack on all week. We can cater to all diets',
        image: '/images/thumbs/food/squash.jpg',
        link: '/service/mealprep',
    }, {
        title: 'Massage',
        description: 'Either in-home massage or you-come-to-us massage.',
        image: '/images/massage.jpg',
        link: '/service/massage',
    }, {
        title: 'Dietitian',
        description: 'Work with our dietitian to create a diet that\'s right for you.',
        image: '/images/dietitian.jpg',
        link: '/service/dietitian',
    },
];

function Services(props) {

    const { classes } = props;

    return (
        <Section title="Services">
            <Grid container item spacing={24} className={classes.cardGrid} justify="center" alignItems="center">
                {services.map(service => (
                    <Grid item key={service.title} xs={12} md={9}>
                        <Card className={classes.card}>
                            <CardActionArea component={Link} to={service.link}>
                                <div className={classes.cardDetails}>
                                    <Hidden smUp>
                                        <CardMedia
                                            component="img"
                                            alt={service.title}
                                            title={service.title}
                                            className={classes.media}
                                            height="160"
                                            image={service.image}
                                        />
                                    </Hidden>
                                    <CardContent>
                                        <Typography component="h2" variant="h5">{service.title}</Typography>
                                        <Typography variant="subtitle1" paragraph>{service.description}</Typography>
                                        <Typography variant="subtitle1" color="primary">Explore</Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                            <Hidden xsDown>
                                <CardMedia
                                    alt={service.title}
                                    title={service.title}
                                    className={classes.bigMedia}
                                    height="160"
                                    image={service.image}
                                />
                            </Hidden>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
}

Services.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Services);