import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

import Section from '../components/Section';
import Testimonial from '../components/Testimonial';
import { getString, getColor } from '../Config';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        color: theme.palette.common.black,
        textAlign: 'center'
    },
});

const persons = [
    {
        name: 'RaeAnn',
        text: getString('About.raeann'),
        image: '/images/testimonial1.jpeg',
    },{
        name: 'Totally Notacat',
        text: getString('About.notacat'),
        image: '/images/testimonial2.jpg',
    }
];

function AboutPage(props) {
    
    const { classes } = props;
    
    return (
        <React.Fragment>
            <Section title="Wabi Sabi Body">
                <Grid item xs={12} md={9}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h5" color="inherit" paragraph>
                                {getString('About.description')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Section>
            <Section title="Collected People">
                <Grid container item spacing={24} className={classes.cardGrid} justify="center" alignItems="center">
                    {persons.map(person => (<Testimonial key={person.name} testimonial={person} />))}
                </Grid>
            </Section>
        </React.Fragment>
    );
}

AboutPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutPage);