import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, TextField } from '@material-ui/core';

import Section from '../../components/Section';
import { getColor } from '../../Config';
import Alert from '../../components/Alert';
import TokenService from '../../ajax/TokenService';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        color: theme.palette.common.black,
        textAlign: 'center'
    },
    buttonRow: {
        textAlign: 'right'
    }
});

class LoginPage extends React.Component {

    static contextTypes = {router: PropTypes.object};
    
    state = {
        username: '',
        password: '',
    };
    
    handleUsernameChange = (e) => this.setState({username: e.target.value});
    handlePasswordChange = (e) => this.setState({password: e.target.value});

    handleLoginClick = (e) => {
        TokenService
            .login(this.state.username, this.state.password)
            .then(() => this.context.router.history.push(`/dashboard`))
            .catch(error => {
                Alert.displayError('Bad username or password');
                console.error('Error: ', error)
            });
    };
    
    render() {
        return (
            <React.Fragment>
                <Section title="Employee Login">
                    <Grid item xs={12} md={6}>
                        <Card className={this.props.classes.card}>
                            <CardContent>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={this.handleUsernameChange}
                                        margin="dense"
                                        id="username"
                                        label="Username *"
                                        type="text"
                                        fullWidth
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={this.handlePasswordChange}
                                        margin="dense"
                                        id="password"
                                        label="Password *"
                                        type="password"
                                        fullWidth
                                        />
                                </Grid>
                                <Grid item xs={12} className={this.props.classes.buttonRow}>
                                    <Button component={Link} to='/'>Cancel</Button>
                                    <Button onClick={this.handleLoginClick}>Login</Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Section>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(LoginPage);