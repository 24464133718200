import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Button, Checkbox, Divider, Grid,
    List, ListItem, ListItemText,
    FormLabel, FormControlLabel,
    Step, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';

import StaticDatePicker from './StaticDatePicker';
import Alert from '../../components/Alert';

const styles = theme => ({
    root: {
        width: '100%',
    },
    calenderGridItem: {
        [theme.breakpoints.down('sm')]: {
            padding: `0px !important`,
        },
    },
    backButton: {
        marginRight: theme.spacing.unit,
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    checkbox: {
        marginLeft: theme.spacing.unit * 4
    },
    center: {
        textAlign: 'center'
    },
    right: {
        textAlign: 'right'
    },
    timeList: {
        maxHeight: '300px',
        overflow: 'auto',
        margin: '5px'
    }
});

class SelectServicePage extends React.Component {
    state = {
        therapist: '',
        totalTime: 0,
        services: {
            raeann: {
                massage: false,
                jinshindo: false,
            },
            someperson: {
                massage: false,
                massage2: false
            }
        },
        enabled: 'all'
    }

    handleChange = event => {
        //this.setState({ therapist: event.target.value });
    };

    handleCheckboxChange = (therapist, service) => event => {
        let { totalTime, services } = this.state;

        services[therapist][service] = event.target.checked;
        totalTime += (event.target.checked ? 1 : -1) * event.target.value;

        let personChecked = Object.entries(this.state.services)
            .filter(person => Object.values(person[1]).reduce((a, b) => a || b))
            .map(person => person[0])
            .join();

        let enabled = personChecked.length === 0 ? 'all' : personChecked;
        this.setState({services: services, totalTime: totalTime, enabled: enabled});
    };

    handleReset = () => {
        this.setState({
            totalTime: 0,
            services: {
                raeann: {
                    massage: false,
                    jinshindo: false
                },
                someperson: {
                    massage: false,
                    massage2: false
                }
            },
            enabled: 'all'
        })
    };

    render() {
        const hours = Math.floor(this.state.totalTime / 60);
        const minutes = this.state.totalTime - (hours * 60);
        const timeChunks = [];

        if (hours > 0) {
            timeChunks.push(`${hours}h`);
        }

        if (minutes > 0) {
            timeChunks.push(`${minutes}m`);
        }

        const timeString = timeChunks.join(' ');

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <FormLabel component="legend">Select one or more services under a therapist:</FormLabel>
                    <Typography><b>RaeAnn</b></Typography>
                    <FormControlLabel
                        control={
                            <Checkbox disabled={!['all', 'raeann'].includes(this.state.enabled)} checked={this.state.services.raeann.massage} onChange={this.handleCheckboxChange('raeann', 'massage')} value="60" className={this.props.classes.checkbox} />
                        }
                        label="Massage (60 min)"
                        />
                    <FormControlLabel
                        control={
                            <Checkbox disabled={!['all', 'raeann'].includes(this.state.enabled)} checked={this.state.services.raeann.jinshindo} onChange={this.handleCheckboxChange('raeann', 'jinshindo')} value="30" className={this.props.classes.checkbox} />
                        }
                        label="Jin Shin Do (30 min)"
                        />
                    <Typography><b>Some Other Person</b></Typography>
                    <FormControlLabel
                        control={
                            <Checkbox disabled={!['all', 'someperson'].includes(this.state.enabled)} checked={this.state.services.someperson.massage} onChange={this.handleCheckboxChange('someperson', 'massage')} value="60" className={this.props.classes.checkbox} />
                        }
                        label="Massage (60 min)"
                        />
                    <FormControlLabel
                        control={
                            <Checkbox disabled={!['all', 'someperson'].includes(this.state.enabled)} checked={this.state.services.someperson.massage2} onChange={this.handleCheckboxChange('someperson', 'massage2')} value="120" className={this.props.classes.checkbox} />
                        }
                        label="Deeper massage (120 min)"
                        />
                </Grid>
                <Grid item xs={12}>
                    <Typography><b>Total Time:</b> {timeString}</Typography>
                    <Button onClick={this.handleReset}>Reset</Button>
                </Grid>
            </React.Fragment>
        );
    }
}

class SelectedListItem extends React.Component {
    state = {
        selectedIndex: 1,
    };

    handleListItemClick = (event, index) => {
        this.setState({ selectedIndex: index });
    };

    render() {
        const { classes } = this.props;

        return (
            <List component="nav" className={classes.timeList}>
                <ListItem>
                    <ListItemText primary="Morning" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 0}
                    onClick={event => this.handleListItemClick(event, 0)}
                    >
                    <ListItemText primary="11:00 am" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 1}
                    onClick={event => this.handleListItemClick(event, 1)}
                    >
                    <ListItemText primary="11:30 am" />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText primary="Afternoon" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 2}
                    onClick={event => this.handleListItemClick(event, 2)}
                    >
                    <ListItemText primary="12:00 pm" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 3}
                    onClick={event => this.handleListItemClick(event, 3)}
                    >
                    <ListItemText primary="12:30 pm" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 4}
                    onClick={event => this.handleListItemClick(event, 4)}
                    >
                    <ListItemText primary="1:00 pm" />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText primary="Evening" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 5}
                    onClick={event => this.handleListItemClick(event, 5)}
                    >
                    <ListItemText primary="6:00 pm" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 6}
                    onClick={event => this.handleListItemClick(event, 6)}
                    >
                    <ListItemText primary="7:30 pm" />
                </ListItem>
                <ListItem
                    button
                    selected={this.state.selectedIndex === 7}
                    onClick={event => this.handleListItemClick(event, 7)}
                    >
                    <ListItemText primary="8:00 pm" />
                </ListItem>
            </List>
        );
    }
}

class SelectTimePage extends React.Component {

    state = {
        selectedDate: new Date()
    }

    setDate = newDate => this.setState({selectedDate: newDate});

    render() {
        const enabledDays = [
            '4/3/2019',
            '4/4/2019',
            '4/10/2019',
            '4/11/2019',
            '4/17/2019',
            '4/18/2019'
        ];

        return (
            <React.Fragment>
                <Grid item xs={12} md={4} className={this.props.classes.center}>
                    <Typography>Appointment Details</Typography>
                    <List dense={true}>
                        <ListItem><ListItemText primary="Massage with RaeAnn" secondary="60m" /></ListItem>
                        <Divider />
                        <ListItem><ListItemText primary="Jin Shin Do with RaeAnn" secondary="30m" /></ListItem>
                        <Divider />
                        <ListItem><ListItemText primary="Total Time" secondary="1h 30m" /></ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4} className={this.props.classes.calenderGridItem}>
                    <Typography>Choose Date</Typography>
                    <StaticDatePicker enabledDays={enabledDays} setDate={this.setDate}/>
                </Grid>
                <Grid item xs={12} md={4} className={this.props.classes.center}>
                    <Typography variant="h4">{this.state.selectedDate.toLocaleDateString("en-US")}</Typography>
                    <Typography>Choose Time</Typography>
                    <SelectTimeListStyle />
                </Grid>
            </React.Fragment>
        );
    }
}

class EnterContactInformationPage extends React.Component {

    render() {
        return (
            <Grid item xs={12}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    />
                <TextField
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    />
                <TextField
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="phone"
                    fullWidth
                    />
                <TextField
                    margin="dense"
                    id="message"
                    name="additionalInformation"
                    label="Additional information for request"
                    type="text"
                    multiline={true}
                    rows={4}
                    rowsMax={4}
                    fullWidth
                    />
            </Grid>
        );
    }
}

class SummaryPage extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={4} className={this.props.classes.center}>
                    <List dense={true}>
                        <ListItem><ListItemText primary="Massage with RaeAnn" secondary="60m" /></ListItem>
                        <Divider />
                        <ListItem><ListItemText primary="Jin Shin Do with RaeAnn" secondary="30m" /></ListItem>
                        <Divider />
                        <ListItem><ListItemText primary="Total Time" secondary="1h 30m" /></ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4} className={this.props.classes.center}>
                    <List dense={true}>
                        <ListItem><ListItemText primary="Appointment Date/Time" secondary="4/3/2019 @ 12:30pm" /></ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4} className={this.props.classes.center}>
                    <List dense={true}>
                        <ListItem><ListItemText primary="Name" secondary="Totally Notacat" /></ListItem>
                        <Divider />
                        <ListItem><ListItemText primary="Email" secondary="notacat@definitelyacat.com" /></ListItem>
                        <Divider />
                        <ListItem><ListItemText primary="Phone" secondary="(515) 555-5555" /></ListItem>
                    </List>
                </Grid>
            </React.Fragment>
        );
    }
}

const SelectServicePageStyle = withStyles(styles)(SelectServicePage);
const SelectTimePageStyle = withStyles(styles)(SelectTimePage);
const SelectTimeListStyle = withStyles(styles)(SelectedListItem);
const EnterContactInformationPageStyle = withStyles(styles)(EnterContactInformationPage);
const SummaryPageStyle = withStyles(styles)(SummaryPage);

function getSteps() {
    return ['Select services', 'Time', 'Contact', 'Summary'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
    case 0:
        return <SelectServicePageStyle />;
    case 1:
        return <SelectTimePageStyle />;
    case 2:
        return <EnterContactInformationPageStyle />;
    case 3:
        return <SummaryPageStyle />;
    default:
        return 'Unknown stepIndex';
    }
}

class MassageAppointmentStepper extends React.Component {
    state = {
        activeStep: 0,
    };

    handleNext = () => {
        if (this.state.activeStep + 1 === getSteps().length) {
            Alert.displayMessage('Thanks for your appointment!');
        }

        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    render() {
        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;

        return (
            <div className={classes.root}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {this.state.activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>Thanks for your appointment!</Typography>
                            <Button onClick={this.handleReset}>Reset</Button>
                        </div>
                    ) : (
                        <Grid container spacing={24} >
                            {getStepContent(activeStep)}
                            <Grid item xs={12} className={classes.right}>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={this.handleBack}
                                    className={classes.backButton}
                                    >
                                    Back
                                </Button>
                                <Button variant="contained" color="primary" onClick={this.handleNext}>
                                    {activeStep === steps.length - 1 ? 'Schedule' : 'Next'}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
}

MassageAppointmentStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(MassageAppointmentStepper);