import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import RequestList from './components/RequestList';
import { getColor } from '../../Config';

const styles = theme => ({
    servicesPaper: {
        backgroundColor: getColor('bg-primary'),
        color: theme.palette.common.black,
        marginBottom: theme.spacing.unit * 4,
    },
    servicesContainer: {
        backgroundColor: getColor('bg-primary'),
        [theme.breakpoints.down('xs')]: {
            padding: `${theme.spacing.unit * 2}px`,
        },
        [theme.breakpoints.up('sm')]: {
            padding: `${theme.spacing.unit * 4}px`,
            paddingTop: `${theme.spacing.unit}px`,
        },
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 4}px`,
            paddingTop: `${theme.spacing.unit * 2}px`,
        }
    },
    center: {
        textAlign: 'center'
    }
});

class DashboardPage extends React.Component {

    static contextTypes = {router: PropTypes.object};

    componentWillMount() {
        if (!sessionStorage.getItem('jwt')) {
            this.context.router.history.push(`login`);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Paper className={this.props.classes.servicesPaper}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={this.props.classes.servicesContainer}>
                                <Typography component="h3" variant="h3" color="inherit" gutterBottom className={this.props.classes.center}>
                                    Dashboard
                                </Typography>
                                <Button component={Link} to="/logout">Logout</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper>
                    <div className={this.props.classes.servicesContainer}>
                        <Grid container spacing={24}>
                            <RequestList type="UNREAD" />
                        </Grid>
                    </div>
                </Paper>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(DashboardPage);