const getFoods = () => {
    return {
        '1': {
            id: 1,
            name: 'Jerk Pork Loin',
            description: 'A jerk pork loin with cajun rice and a pineapple cucumber salsa.',
            image: '/images/thumbs/food/pork.jpg',
            tags: [],
            badTags: [],
        },
        '2': {
            id: 2,
            name: 'Beef Tenderloin',
            description: 'Beef tenderloin marinated in chimichurri sauce with goat cheese polenta and roasted asparagus.',
            image: '/images/thumbs/food/beef.jpg',
            tags: [],
            badTags: [],
        },
        '3': {
            id: 3,
            name: 'Chicken Mole',
            description: 'Pulled chicken mole.',
            image: '/images/thumbs/food/mole.jpg',
            tags: [],
            badTags: [],
        },
        '4': {
            id: 4,
            name: 'Tamales',
            description: 'Several varieties. Pictured: roasted pulled bbq pork and cheddar (homemade bbq sauce), tomato chipotle chicken (brined), and roasted veggie con queso.',
            image: '/images/thumbs/food/tamales2.jpg',
            tags: ['Gluten Free'],
            badTags: [],
        },
        '5': {
            id: 5,
            name: 'Roasted Squash Soup',
            description: 'Roasted squash soup made with a homemade cashew cream, topped with pepitas, feta, a paprika avocado oil, and a pumpkin feta muffin. Can be made vegan and GF.',
            image: '/images/thumbs/food/squash.jpg',
            tags: ['Vegan', 'Gluten Free'],
            badTags: [],
        },
        '6': {
            id: 6,
            name: 'Kiwi Lime Cheesecake Bites',
            description: 'Raw vegan gluten free kiwi lime cheesecake bites.',
            image: '/images/thumbs/food/kiwi.jpg',
            tags: ['Raw Vegan', 'Vegan', 'Gluten Free'],
            badTags: [],
        },
        '7': {
            id: 7,
            name: 'Chocolate Chip Cookie Dough Bites',
            description: '',
            image: '/images/thumbs/food/chocolatechip.jpg',
            tags: ['Vegan', 'Gluten Free', 'Nut Free', 'Refined Sugar Free'],
            badTags: [],
        },
        '8': {
            id: 8,
            name: 'Raw Vegan Lemon Blueberry Cheesecake Bites',
            description: '',
            image: '/images/thumbs/food/cheesecake.jpg',
            tags: ['Raw Vegan', 'Vegan', 'Gluten Free', 'Refined Sugar Free'],
            badTags: [],
        },
        '9': {
            id: 9,
            name: 'Banana Upside Down Cake',
            description: '',
            image: '/images/thumbs/food/cake.jpg',
            tags: ['Not', 'Sure', 'Exactly'],
            badTags: [],
        },
        '10': {
            id: 10,
            name: 'Lemon Cake',
            description: 'Double layer olive oil lemon cake with homemade lemon curd frosting and local blueberries.',
            image: '/images/thumbs/food/lemoncake.jpg',
            tags: [],
            badTags: ['HAS gluten', 'HAS dairy', 'HAS sugar'],
        },
    };
}

const getSections = () => {
    return {
        meats: {
            id: 1,
            title: 'Meat Entrees',
            description: 'A selection of meat dishes that are available.',
            foods: ['1', '2', '3', '4']
        },
        nonMeats: {
            id: 2,
            title: 'Non-Meat Entrees',
            description: 'A selection of non-meat entrees that are available.',
            foods: ['5']
        },
        desserts: {
            id: 3,
            title: 'Desserts',
            description: 'A selection of desserts.',
            foods: ['6', '7', '8', '9', '10']
        }
    };
}

export {
    getFoods,
    getSections,
}