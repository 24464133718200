import { getApiAddress } from '../Config';

class TokenService {

    static interval;

    static startRefreshProcess() {
        clearInterval(TokenService.interval);

        TokenService.interval = setInterval(TokenService.refresh, 60000);
    }

    static async login(username, password) {
        const data = {
            username: username,
            password: password
        };

        return fetch(`${getApiAddress()}token/create`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    throw Error('Bad username or password.');
                }
                return response;
            })
            .then(response => response.json())
            .then(response => sessionStorage.setItem('jwt', response.jwt))
            .then(() => TokenService.startRefreshProcess());
    }

    static async refresh(request) {
        const jwt = sessionStorage.getItem('jwt');

        if (jwt === null) {
            clearInterval(TokenService.interval);
            return;
        }

        fetch(`${getApiAddress()}token/refresh`, {
            method: 'POST',
            body: JSON.stringify({jwt: jwt}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwt
            }
        })
            .then(response => response.json())
            .then(response => sessionStorage.setItem('jwt', response.jwt))
            .catch(error => alert(error));
    }
}

export default TokenService;