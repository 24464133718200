const blogs = [
    {
        id: 1,
        blurb: `
## shabby chic poutine chicharrones before they sold out

#### March 29, 2019 by RaeAnn

Literally narwhal echo park kale chips whatever mumblecore cray, copper mug hashtag fixie selfies adaptogen williamsburg. Kickstarter tacos tattooed, schlitz swag PBR&B DIY etsy organic master cleanse literally synth. Succulents gluten-free retro artisan normcore street art. Pour-over irony woke, venmo meggings sustainable portland hammock actually hoodie gochujang live-edge.
`,
        full: `
## shabby chic poutine chicharrones before they sold out

#### March 29, 2019 by RaeAnn

Literally narwhal echo park kale chips whatever mumblecore cray, copper mug hashtag fixie selfies adaptogen williamsburg. Kickstarter tacos tattooed, schlitz swag PBR&B DIY etsy organic master cleanse literally synth. Succulents gluten-free retro artisan normcore street art. Pour-over irony woke, venmo meggings sustainable portland hammock actually hoodie gochujang live-edge.

Jianbing +1 art party, chia meh fixie shabby chic next level seitan tacos adaptogen slow-carb VHS shoreditch tattooed. Readymade man braid taxidermy palo santo prism. Vice chia farm-to-table mixtape austin whatever affogato selvage. Portland pour-over air plant forage. Direct trade intelligentsia umami thundercats kinfolk. Banh mi twee copper mug typewriter literally street art beard.

Gochujang bushwick you probably haven't heard of them, dreamcatcher austin cliche intelligentsia chartreuse. Brunch intelligentsia kickstarter gluten-free salvia blue bottle letterpress VHS taiyaki before they sold out small batch man braid. Kale chips taiyaki vape, PBR&B flexitarian flannel fixie hammock williamsburg occupy glossier activated charcoal jianbing roof party. Cronut green juice try-hard vape art party fam forage tofu food truck. Readymade chambray meggings kale chips wolf raw denim, chicharrones gentrify heirloom direct trade taiyaki small batch artisan hexagon. Meditation kale chips master cleanse, deep v raw denim lomo tacos tbh pickled.

Copper mug humblebrag four dollar toast etsy truffaut thundercats beard woke unicorn whatever. Biodiesel cliche hexagon blue bottle, next level affogato bushwick snackwave normcore flannel. Meditation occupy yr, williamsburg sriracha banh mi food truck swag small batch flexitarian fam. YOLO kogi glossier stumptown meditation austin. Church-key poke selvage lo-fi. Mixtape meh farm-to-table narwhal trust fund.

Poke kale chips mlkshk viral freegan messenger bag health goth hammock mixtape gentrify pork belly chicharrones. PBR&B raclette vape fashion axe humblebrag. Jianbing humblebrag PBR&B, letterpress yr mlkshk ugh banjo you probably haven't heard of them prism mumblecore +1 helvetica farm-to-table adaptogen. Succulents skateboard deep v, polaroid tofu truffaut microdosing bicycle rights gluten-free green juice typewriter flexitarian. Four loko cred waistcoat actually brunch squid wayfarers cold-pressed succulents polaroid shoreditch distillery. Authentic truffaut hashtag, shabby chic poutine chicharrones before they sold out gluten-free beard af +1.        
`
    },
    {
        id: 2,
        blurb: `
## asymmetrical beard vape

#### March 30, 2019 by Notacat

Tumeric post-ironic leggings keytar shabby chic fanny pack single-origin coffee tote bag williamsburg man braid locavore vaporware raclette brooklyn copper mug. Thundercats asymmetrical green juice schlitz vape coloring book. Umami four dollar toast kickstarter kogi hella marfa. Gochujang chartreuse cray poke, coloring book cornhole snackwave. Post-ironic sustainable intelligentsia seitan, man braid snackwave yuccie. Forage tofu literally humblebrag organic, sriracha tacos selfies pabst selvage small batch offal. Deep v salvia asymmetrical beard vape yuccie keffiyeh etsy trust fund pickled.
        `,
        full: `
## asymmetrical beard vape

#### March 30, 2019 by Notacat

Tumeric post-ironic leggings keytar shabby chic fanny pack single-origin coffee tote bag williamsburg man braid locavore vaporware raclette brooklyn copper mug. Thundercats asymmetrical green juice schlitz vape coloring book. Umami four dollar toast kickstarter kogi hella marfa. Gochujang chartreuse cray poke, coloring book cornhole snackwave. Post-ironic sustainable intelligentsia seitan, man braid snackwave yuccie. Forage tofu literally humblebrag organic, sriracha tacos selfies pabst selvage small batch offal. Deep v salvia asymmetrical beard vape yuccie keffiyeh etsy trust fund pickled.

Tofu austin mixtape yuccie distillery kickstarter wayfarers. You probably haven't heard of them aesthetic glossier authentic pork belly, celiac affogato gentrify art party swag hammock seitan. Slow-carb intelligentsia umami gastropub pug. You probably haven't heard of them cray listicle, shoreditch yuccie put a bird on it wolf cliche selvage semiotics. Tbh post-ironic woke pour-over, vice etsy wolf typewriter fingerstache brooklyn everyday carry tacos fam. Iceland authentic meh palo santo humblebrag cray.

Man braid pitchfork kale chips, tbh salvia snackwave raclette locavore slow-carb portland tattooed. Chia photo booth meditation, mixtape banjo twee edison bulb. Wolf vape skateboard, crucifix bitters XOXO narwhal activated charcoal you probably haven't heard of them sriracha pour-over. Kickstarter edison bulb polaroid cornhole keytar small batch jianbing ethical blue bottle. Celiac pitchfork subway tile jean shorts single-origin coffee woke pop-up fingerstache fanny pack church-key. Deep v flexitarian literally, master cleanse cliche blue bottle 8-bit jianbing selfies.

Organic fam brunch roof party unicorn. Letterpress fashion axe prism hexagon, asymmetrical flexitarian post-ironic wayfarers beard quinoa echo park yr locavore occupy. Wayfarers schlitz taiyaki williamsburg succulents asymmetrical. Venmo try-hard etsy keytar kinfolk polaroid lomo. Tbh paleo chartreuse, sartorial vaporware vinyl letterpress.

Pok pok hella intelligentsia banjo, hot chicken austin blog. Gochujang synth copper mug taxidermy, pinterest humblebrag slow-carb selvage gentrify jianbing jean shorts everyday carry four dollar toast cray. Gluten-free kale chips biodiesel swag hexagon intelligentsia. Ramps gochujang kitsch chia pour-over cold-pressed plaid schlitz.
        `
    },
    {
        id: 3,
        blurb: `
## banh mi dreamcatcher

#### March 31, 2019 by RaeAnn

Lo-fi subway tile mumblecore readymade meh. PBR&B cray pok pok yuccie cold-pressed. Master cleanse mustache lyft edison bulb. Mustache lyft taxidermy wolf farm-to-table, messenger bag umami cardigan. Humblebrag neutra chia asymmetrical put a bird on it gentrify sustainable, typewriter lo-fi church-key tbh affogato hexagon whatever. Biodiesel pop-up etsy ennui hot chicken DIY, banh mi live-edge gochujang microdosing snackwave.
        `,
        full: `
## banh mi dreamcatcher

#### March 31, 2019 by RaeAnn

Lo-fi subway tile mumblecore readymade meh. PBR&B cray pok pok yuccie cold-pressed. Master cleanse mustache lyft edison bulb. Mustache lyft taxidermy wolf farm-to-table, messenger bag umami cardigan. Humblebrag neutra chia asymmetrical put a bird on it gentrify sustainable, typewriter lo-fi church-key tbh affogato hexagon whatever. Biodiesel pop-up etsy ennui hot chicken DIY, banh mi live-edge gochujang microdosing snackwave.

Echo park pork belly glossier, knausgaard messenger bag ethical yuccie tousled raw denim deep v cred kogi subway tile activated charcoal. Raclette vice wolf banjo chambray VHS bitters, typewriter literally disrupt. Ethical direct trade ugh you probably haven't heard of them kickstarter deep v, heirloom single-origin coffee cloud bread neutra shabby chic vinyl dreamcatcher scenester lomo. Heirloom food truck messenger bag viral stumptown franzen green juice 8-bit tumeric authentic narwhal glossier jean shorts pug. Offal vice tilde, locavore fingerstache blue bottle VHS semiotics helvetica chia umami.

Tumblr gluten-free pour-over cardigan mustache cronut hexagon hella jean shorts man bun skateboard tbh biodiesel vaporware. Four loko vinyl tousled occupy lumbersexual. Scenester four dollar toast normcore yr selfies church-key lumbersexual. Pinterest listicle direct trade vexillologist. Church-key kogi street art, heirloom hella meggings whatever banh mi yr narwhal.

Franzen pug actually mumblecore vinyl semiotics. Keytar meggings normcore skateboard air plant coloring book vape. Dreamcatcher fixie actually viral shaman lo-fi. Pinterest prism cardigan succulents cornhole edison bulb. Hashtag man braid taxidermy iceland enamel pin edison bulb biodiesel crucifix next level skateboard vaporware. Prism kinfolk slow-carb microdosing, quinoa messenger bag pug schlitz trust fund tbh. Truffaut banh mi woke hashtag farm-to-table copper mug tilde, flexitarian kickstarter.

Beard chillwave quinoa aesthetic, enamel pin marfa schlitz woke small batch echo park chicharrones actually scenester pork belly messenger bag. Banjo forage before they sold out, gentrify pabst hammock sustainable pitchfork keffiyeh literally affogato single-origin coffee fam vaporware. Trust fund put a bird on it keffiyeh meh hell of prism. Ennui palo santo literally artisan, bushwick kickstarter truffaut keffiyeh VHS fixie cornhole intelligentsia pop-up. Man braid tumblr kitsch, disrupt before they sold out waistcoat +1 coloring book. Forage heirloom organic keytar cloud bread try-hard snackwave brunch helvetica austin marfa hell of pinterest. Street art YOLO poutine taxidermy taiyaki austin blog authentic food truck flexitarian fingerstache viral retro.
        `
    }
]

class BlogService {

    async getPage(pageNumber = 1) {
        return new Promise(resolve => resolve([...blogs].reverse()));
    }

    async getBlog(blogId) {
        return new Promise(resolve => resolve(blogs[parseInt(blogId - 1)]));
    }

    async getMostRecentBlog() {
        return new Promise(resolve => resolve(blogs[blogs.length - 1]));
    }

}

export default BlogService;