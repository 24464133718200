import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent, Grid, List, ListItem, ListItemText, ListSubheader, 
    Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';

import { getColor } from '../../../Config';
import Alert from '../../../components/Alert';
import QuoteService from '../../../ajax/QuoteService';

const styles = theme => ({
    card: {
        backgroundColor: getColor('bg-secondary'),
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
});

class RequestListItemNoteDialog extends Component {
    
    state = {
        open: false,
        noteText: ''
    }

    constructor(props) {
        super(props);
        this.quoteService = new QuoteService();
    }

    openDialog = () => {
        this.setState({open: true, noteText: ''});
    }

    handleClose = () => {
        this.setState({open: false});
    }

    handleSave = () => {
        this.quoteService.addNote(this.props.requestId, this.state.noteText)
            .then(() => {
                this.props.refetch();
                Alert.displayMessage('Note successfully added.');
                this.handleClose();
            })
            .catch(error => {
                console.error(error);
                Alert.displayError('An error occurred while adding note.');
                this.handleClose();
            })
    }

    handleNoteChange = (event) => {
        this.setState({noteText: event.target.value});
    }

    render() {
        return (
            <React.Fragment>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="add-note-title">
                    <DialogTitle id="add-note-title">Add Note</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter a note for the quote/appointment.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="note"
                            label="Note"
                            type="text"
                            fullWidth
                            onChange={this.handleNoteChange}
                            multiline={true}
                            rows={4}
                            />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="inherit">Cancel</Button>
                        <Button onClick={this.handleSave} color="primary">Add Note</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

class RequestListItem extends Component {

    constructor(props) {
        super(props);
        this.quoteService = new QuoteService();
        this.dialogRef = React.createRef();
        this.handleAddNoteClicked = this.handleAddNoteClicked.bind(this);
    }

    handleAddNoteClicked() {
        console.log(this.dialogRef);
        this.dialogRef.current.openDialog(this.props.request.id);
    }

    render() {
        let notes = (<ListItem><ListItemText>There are no notes.</ListItemText></ListItem>);

        if (this.props.request.notes.length > 0) {
            notes = this.props.request.notes.map(note => (
                <ListItem key={note.id} className={this.props.classes.nested}>
                    <ListItemText>{note.createdAt}: {note.note}</ListItemText>
                </ListItem>
            ))
        }

        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Card className={this.props.classes.card}>
                        <div className={this.props.classes.cardDetails}>
                            <RequestListItemNoteDialog ref={this.dialogRef} requestId={this.props.request.id} refetch={this.props.refetch} />
                            <CardContent>
                                <List subheader={<ListSubheader component="div">{this.props.request.serviceName} <small>(Request #{this.props.request.id})</small></ListSubheader>} dense={true}>
                                    <ListItem>
                                        <ListItemText><b>Selected sub-services</b></ListItemText>
                                    </ListItem>
                                    <List component="div" disablePadding dense={true}>
                                        {this.props.request.details.map(detail => (
                                            <ListItem key={detail.id} className={this.props.classes.nested}>
                                                <ListItemText>{detail.detail}</ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                    <ListItem>
                                        <ListItemText><b>Quote received:</b> {this.props.request.createdAt}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText><b>Contact information:</b> {this.buildNameLine(this.props.request)}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText><b>Desired date/time:</b> {this.props.request.desiredTime}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText><b>Additional information:</b> {this.props.request.additionalInformation}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText><b>Notes</b></ListItemText>
                                    </ListItem>
                                    <List component="div" disablePadding dense={true}>
                                        {notes}
                                        <ListItem>
                                            <Button color="inherit" onClick={this.handleAddNoteClicked}>Add Note</Button>
                                        </ListItem>
                                    </List>
                                </List>
                                
                                <Button color="inherit">Mark as Read</Button>
                                <Button color="inherit">Delete</Button>
                            </CardContent>
                        </div>
                    </Card>
                </Grid>
            </React.Fragment>
        );
    }

    buildNameLine(request) {
        return [request.name, request.email, request.phone]
            .filter(value => value !== null && value !== '')
            .join(', ');
    }
}

export default withStyles(styles)(RequestListItem);