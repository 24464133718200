import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import Section from '../../components/Section';
import SectionCard from '../../components/SectionCard';
import RequestQuoteDialog from '../../components/RequestQuoteDialog';

const styles = theme => ({
    fab: {
        margin: 0,
        top: 'auto',
        left: 'auto',
        bottom: 20,
        right: 20,
        position: 'fixed',        
        zIndex: 5000
    },
});

function DietitianPage(props) {

    const { classes } = props;

    const showQuoteDialog = () => {
        RequestQuoteDialog.openDialog({
            type: 'Appointment',
            typeDisplay: 'an appointment',
            service: 'Dietitian',
            detailedServices: ['Dietitian Appointment'],
        });
    };

    return (
        <Section title="Dietitian">
            <SectionCard>
                <Typography variant="h5" color="inherit" paragraph className={classes.center}>
                    Meet with our dietitian to decide on a diet that's right for you.
                </Typography>
                <Button color="primary" onClick={showQuoteDialog}>
                    Request an appointment
                </Button>
                <Typography variant="h5" color="inherit" paragraph className={classes.center}>
                    Some more words here if needed. Lorem ipsum dolor amet gastropub crucifix bicycle rights art party. Ramps cray viral knausgaard, tilde hot chicken irony marfa beard intelligentsia raclette brunch. Put a bird on it waistcoat cornhole cold-pressed intelligentsia deep v raw denim adaptogen whatever fixie iceland organic taiyaki. Blog venmo four dollar toast tattooed, artisan banjo schlitz dreamcatcher vinyl live-edge kogi farm-to-table green juice ugh. Cred banh mi health goth art party yr jianbing chia edison bulb. Disrupt glossier tumblr retro DIY beard.
                </Typography>
            </SectionCard>
        </Section>
    );
}

DietitianPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DietitianPage);