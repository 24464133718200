import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Section from './Section';
import Testimonial from './Testimonial';

const styles = theme => ({
    center: {
        textAlign: 'center'
    },
});

const testimonials = [
    {
        name: 'Real Person',
        text: [`Lorem ipsum dolor amet fashion axe trust fund disrupt wolf everyday carry. +1 kogi migas poutine deep v snackwave quinoa actually. Ethical PBR&B tofu flexitarian mixtape beard. Fanny pack portland lomo waistcoat, stumptown jean shorts coloring book vegan. 90's keffiyeh distillery yr deep v austin meditation YOLO beard.`],
        image: '/images/testimonial1.jpeg',
    },{
        name: 'Another Real Person',
        text: [`Lorem ipsum dolor amet tattooed trust fund locavore 90's. Artisan slow-carb bicycle rights, ethical subway tile +1 copper mug poke typewriter you probably haven't heard of them vinyl. PBR&B air plant lo-fi, normcore lumbersexual gluten-free fam small batch pabst 3 wolf moon. Bitters put a bird on it jianbing, listicle hella semiotics PBR&B you probably haven't heard of them chia. Adaptogen photo booth godard, shoreditch taxidermy migas gentrify next level squid raw denim you probably haven't heard of them chartreuse single-origin coffee food truck XOXO. Chambray photo booth pour-over mixtape, coloring book palo santo fam portland offal master cleanse you probably haven't heard of them letterpress aesthetic 8-bit unicorn. Dreamcatcher literally snackwave, wolf unicorn man bun master cleanse pitchfork.`],
        image: '/images/testimonial2.jpg',
    },{
        name: 'The Realest Person',
        text: [`Lorem ipsum dolor amet banjo lomo subway tile wolf microdosing. Biodiesel street art brunch wayfarers bushwick raw denim. Bitters crucifix pug mustache stumptown umami kitsch kickstarter bespoke. Stumptown street art cray tumeric. Glossier chillwave tumblr, authentic live-edge butcher blue bottle tacos hashtag lyft. Yuccie photo booth aesthetic succulents ennui coloring book banh mi dreamcatcher meditation snackwave flannel disrupt.`],
        image: '/images/testimonial3.jpg',
    },
];

function Testimonials(props) {
    
    return (
        <Section title="Testimonials">
            <Grid container item spacing={24} justify="center" alignItems="center">
                {testimonials.map(testimonial => (<Testimonial key={testimonial.name} testimonial={testimonial} />))}
            </Grid>
        </Section>
    );
}

Testimonials.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Testimonials);