import { getApiAddress } from '../Config';

class QuoteService {

    async getRequests(type) {
        return fetch(`${getApiAddress()}quote/requests?status=${type}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('jwt')}`
            }
        });
    }

    async createRequest(request) {
        return fetch(`${getApiAddress()}quote/request`, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    async addNote(requestId, note) {
        const request = {note: note};

        return fetch(`${getApiAddress()}quote/request/${requestId}/addNote`, {
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${sessionStorage.getItem('jwt')}`
            }
        })
            .then(response => {
                if (response.status !== 200) {
                    throw Error('An error occurred while add note.');
                }
                return response;
            });
    }
}

export default QuoteService;