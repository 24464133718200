import React, { Component } from 'react';

import FoodSection from './FoodSection';
import { getSections } from '../ajax/FoodService';

class FoodSections extends Component {

    render() {
        return (
            <React.Fragment>
                {Object.values(getSections()).map(section => (
                    <FoodSection key={section.id} section={section} checkedState={this.props.checkedState} checkedChanged={this.props.checkedChanged} selectedTags={this.props.selectedTags}/>
                ))}
            </React.Fragment>
        );
    }
}

export default FoodSections;